<template>
  <div id="myselfRole">
    <Back title="我的角色"></Back>
    <main>
      <!-- 服务商选择 -->
      <section class="block-item">
        <div class="block-title van-hairline--bottom">
          <div class="title-left"><i></i>服务商选择</div>
          <div class="title-right">
            <div class="title-right theme-color"></div>
          </div>
        </div>
        <div class="block-content">
          <p
            v-for="item in spUserList"
            :key="item.spId"
            :class="{ active: params.spId == item.spId }"
            @click="onSpId(item.spId)"
          >
            {{ item.spName }}
          </p>
        </div>
      </section>
      <!-- 部门选择 -->
      <section class="block-item">
        <div class="block-title van-hairline--bottom">
          <div class="title-left"><i></i>部门选择</div>
          <div class="title-right">
            <div class="title-right theme-color"></div>
          </div>
        </div>
        <div class="block-content">
          <p
            v-for="item in deptList"
            :key="item.deptId"
            :class="{ active: params.deptId == item.deptId }"
            @click="onDeptId(item.deptId)"
          >
            {{ item.deptName }}
          </p>
        </div>
      </section>
      <!-- 角色选择 -->
      <section class="block-item">
        <div class="block-title van-hairline--bottom">
          <div class="title-left"><i></i>角色选择</div>
          <div class="title-right">
            <div class="title-right theme-color"></div>
          </div>
        </div>
        <div class="block-content">
          <p
            v-for="item in roleList"
            :key="item.roleId"
            :class="{ active: params.roleId == item.roleId }"
            @click="onRoleId(item.roleId)"
          >
            {{ item.roleName }}
          </p>
        </div>
      </section>
      <div class="button" @click="saveMode">保存</div>
    </main>
  </div>
</template>

<script>
import Back from "@/components/back";
import {
  findSpUserList,
  findDeptList,
  findRoleList,
  saveMode,
} from "@/api/myself/myself";
import { Toast } from "vant";

export default {
  components: { Back },
  data() {
    return {
      spUserList: [], //服务商列表
      deptList: [], //部门列表
      roleList: [], //角色列表
      params: {
        appMode: 3, //管理员
        spId: "", //服务商id
        deptId: "", //部门id
        roleId: "", //角色id
      },
    };
  },
  created() {
    this.findSpUserList();
  },
  methods: {
    // 【请求】获取服务商列表
    findSpUserList() {
      let _this = this;
      findSpUserList().then((res) => {
        if (res.data) {
          this.spUserList = res.data;
          let isTrue = res.data.some((i) => i.first == true);
          if (isTrue) {
            for (let i of res.data) {
              if (i.first) {
                _this.params.spId = i.spId;
                _this.findDeptList(i.spId);
                _this.findRoleList(i.spId);
              }
            }
          } else {
            _this.params.spId = res.data[0].spId;
            _this.findDeptList(res.data[0].spId);
            _this.findRoleList(res.data[0].spId);
          }
        }
      });
    },

    // 【请求】获取服务商列表
    findDeptList(spId) {
      let data = {
        spId,
      };
      findDeptList(data).then((res) => {
        if (res.data) {
          this.deptList = res.data;
          for (let i of res.data) {
            if (i.first) {
              this.params.deptId = i.deptId;
            }
          }
        }
      });
    },

    // 【请求】获取服务商列表
    findRoleList(spId) {
      let data = {
        spId,
      };
      findRoleList(data).then((res) => {
        if (res.data) {
          this.roleList = res.data;
          for (let i of res.data) {
            if (i.first) {
              this.params.roleId = i.roleId;
            }
          }
        }
      });
    },

    // 【请求】保存角色修改
    saveMode() {
      let data = this.params;
      saveMode(data).then((res) => {
        if (res.success) {
          Toast("角色切换成功");
          this.$router.go(-1);
        }
      });
    },

    // 【监听】服务商选择
    onSpId(spid) {
      this.params.spId = spid;
      this.findDeptList(spid)
      this.findRoleList(spid)
    },

    // 【监听】服务商选择
    onDeptId(deptId) {
      this.params.deptId = deptId;
    },

    // 【监听】服务商选择
    onRoleId(roleId) {
      this.params.roleId = roleId;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";
#myselfRole {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  overflow: auto;
  .button {
    margin: 0.3rem 0.1rem;
    height: 0.44rem;
    text-align: center;
    line-height: 0.44rem;
    font-size: 0.16rem;
    color: #ffffff;
    background: #4f94f3;
    box-shadow: 0rem 0.09rem 0.16rem 0rem rgba(79, 148, 243, 0.35);
    border-radius: 0.05rem;
  }
}

// 文本模块
.block-item {
  border-top: $bg-color 0.15rem solid;
  display: flex;
  flex-direction: column;
  padding: 0 0.16rem 0.16rem 0.2rem;
  box-sizing: border-box;
  > .block-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.1rem 0;
    box-sizing: border-box;
    .title-left {
      display: flex;
      align-items: center;
      > i {
        display: inline-block;
        background: $theme-color;
        width: 0.04rem;
        height: 0.15rem;
        border-radius: 0 0.04rem 0.04rem 0;
        margin-right: 0.1rem;
      }
    }
  }
  // 内容区上边距
  .block-content {
    margin-top: 0.19rem;
    border-radius: 4px;
    overflow-x: auto;
    overflow-y: hidden;
    display: -webkit-box;
    p {
      width: 1.29rem;
      box-sizing: border-box;
      padding: 0.1rem 0.1rem;
      background: #ffffff;
      border-radius: 0.04rem;
      border: 0.01rem solid #dddddd;
      margin-right: 0.1rem;
      font-size: 0.15rem;
      color: #333333;
      line-height: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }
    .active {
      color: #4f94f3;
      border: 0.01rem solid #4f94f3;
      position: relative;
    }
    .active::before {
      content: "";
      display: block;
      width: 0.1rem;
      height: 0.1rem;
      position: absolute;
      bottom: 0.01rem;
      right: 0.01rem;
      background: url("~@/assets/images/myself/xuanzhong.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>